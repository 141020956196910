import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Hero from '../components/hero'
import Services from '../components/services'
import About from '../components/about'
import Testimonials from '../components/testimonials'
import Contact from '../components/contact'
import Footer from '../components/footer'

const IndexPage = ({ data, pageContext: { locale } }) => (
  <Layout locale={locale}>
    <Hero
      data={data.text.childIndex.hero}
      image={data.hero.childImageSharp.fluid}
    />
    <Services
      data={data.text.childIndex.services}
      images={[
        data.services_1.childImageSharp.fluid,
        data.services_2.childImageSharp.fluid,
        data.services_3.childImageSharp.fluid,
        data.services_4.childImageSharp.fluid,
      ]}
    />
    <About
      data={data.text.childIndex.about}
      image={data.founder.childImageSharp.fluid}
    />
    <Testimonials
      data={data.text.childIndex.testimonials}
      // each edge object contains a node field under which
      // `childImageSharp` and `name` are found
      images={data.logos.edges}
    />
    <Contact data={data.text.childIndex.contact} locale={locale} />
    <Footer
      data={data.text.childIndex.footer}
      image={data.logo.childImageSharp.fixed}
    />
  </Layout>
)

export const query = graphql`
  query($locale: String) {
    text: file(name: { eq: $locale }, relativeDirectory: { eq: "index" }) {
      childIndex {
        hero {
          title
          subtitle
          button
        }
        services {
          title
          subtitle
          cards {
            title
            description
          }
          button
        }
        about {
          title
          text
          founder_name
          founder_title
        }
        testimonials {
          title
          subtitle
          cards {
            quote
            name
            link
            image_id
          }
        }
        contact {
          title
          subtitle
          form {
            name
            email
            message
            button
          }
        }
        footer {
          company_name
        }
      }
    }
    logo: file(relativePath: { regex: "/nessia-logo/" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    hero: file(relativePath: { regex: "/workspace/" }) {
      childImageSharp {
        fluid(maxWidth: 2932) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    services_1: file(relativePath: { regex: "/teacher/" }) {
      childImageSharp {
        fluid(maxWidth: 1120) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    services_2: file(relativePath: { regex: "/conducter/" }) {
      childImageSharp {
        fluid(maxWidth: 1120) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    services_3: file(relativePath: { regex: "/architect/" }) {
      childImageSharp {
        fluid(maxWidth: 1120) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    services_4: file(relativePath: { regex: "/advice/" }) {
      childImageSharp {
        fluid(maxWidth: 1120) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    founder: file(relativePath: { regex: "/francois/" }) {
      childImageSharp {
        fluid(maxWidth: 170) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    logos: allFile(filter: { sourceInstanceName: { eq: "logos" } }) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
          name
        }
      }
    }
  }
`

export default IndexPage
