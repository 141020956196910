import React from 'react'
import Img from 'gatsby-image'
import Title from './Title'
import Wave from '../images/wave.svg'
import LinkedIn from '../images/linkedin.svg'
import styles from '../styles/about.module.scss'

const About = ({ data, image }) => (
  <>
    <div className="has-background-light is-clipped">
      <Wave className="wave" />
    </div>
    <section
      id="about"
      className={`section is-medium has-background-primary ${styles.container}`}
    >
      <Title title={data.title} __className="mb4" white />
      <div className="columns is-centered is-mobile">
        <div className="column is-two-thirds-tablet is-half-desktop">
          <div className="box">
            <blockquote className="is-size-5-fullhd is-size-6 has-text-justified">
              <p className="mb1">{data.text[0]}</p>
              <p>{data.text[1]}</p>
            </blockquote>
            <div className={styles.avatar}>
              <Img
                style={{ borderRadius: '100%', width: '96px', height: '96px' }}
                alt="NessIA president"
                fluid={image}
              />
              <div className={styles.avatar_text}>
                <span className="title is-size-4-fullhd is-size-5">
                  {data.founder_name}
                </span>
                <span className="subtitle is-size-5-fullhd is-size-6">
                  {data.founder_title}
                </span>
                <a
                  href="https://www.linkedin.com/in/fran%C3%A7ois-dumont-4053334/"
                  title="François Dumont LinkedIn"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="image is-24x24"
                >
                  <LinkedIn />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
)

export default About
