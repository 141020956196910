import React from 'react'
import PropTypes from 'prop-types'
import styles from '../styles/testimonial-card.module.scss'

/**
 * Individual item for the carousel.
 * @param {Object} props
 * @param {ReactElement} props.Logo Img as react component
 * @param {object} props.data json data, see proptypes
 */
export const TestimonialCard = ({ Logo, data: { quote, name, link } }) => (
  <div className={`${styles.container} has-text-grey-dark`}>
    <div
      className={
        // hack for MDA because the image is too damn high
        /Modern Data Analytics/.test(name) ? styles.logo_mda : styles.logo
      }
    >
      <a href={link} target="_blank" rel="noopener noreferrer">
        <Logo />
      </a>
    </div>
    <div style={{ width: '100%' }}>
      {quote && (
        <blockquote className="is-size-4-fullhd is-size-5 is-size-6-mobile">
          {quote}
        </blockquote>
      )}
      {name && (
        <span className="is-size-5-fullhd is-size-6 has-text-weight-semibold">
          {name}
        </span>
      )}
    </div>
  </div>
)

TestimonialCard.propTypes = {
  logo: PropTypes.element,
  data: PropTypes.shape({
    quote: PropTypes.string,
    name: PropTypes.string,
    link: PropTypes.string.isRequired,
  }).isRequired,
}

/**
 * Individual logo item
 * @param {ReactElement} props.Logo Img as react component
 * @param {string} props.link
 */
export const LogoCard = ({ Logo, link }) => (
  <div className={styles.outside}>
    <a href={link} target="_blank" rel="noopener noreferrer">
      <Logo />
    </a>
  </div>
)
