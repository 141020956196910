import React from 'react'
import Img from 'gatsby-image'
import scrollTo from 'gatsby-plugin-smoothscroll'
import Title from './Title'
import styles from '../styles/services.module.scss'

const Services = ({ data, images }) => (
  <section id="services" className="section is-medium has-background-light">
    <div
      className={`container has-text-centered-mobile ${styles.columns_container}`}
    >
      <Title title={data.title} subtitle={data.subtitle} />

      <div className="columns">
        <div className="column is-one-third">
          <Img className={styles.image} fluid={images[3]} />
        </div>
        <div className="column is-two-thirds">
          <h3 className="title is-size-3-fullhd is-size-4">
            {data.cards[0].title}
          </h3>
          <p className="is-size-5-fullhd is-size-6">
            {data.cards[0].description}
          </p>
        </div>
      </div>

      <div className={`columns ${styles.reversed}`}>
        <div className="column is-two-thirds">
          <h3 className="title is-size-3-fullhd is-size-4">
            {data.cards[1].title}
          </h3>
          <p className="is-size-5-fullhd is-size-6">
            {data.cards[1].description}
          </p>
        </div>
        <div className="column is-one-third">
          <Img
            className={`is-image-mirrored ${styles.image}`}
            fluid={images[1]}
          />
        </div>
      </div>

      <div className="columns">
        <div className="column is-one-third">
          <Img className={styles.image} fluid={images[2]} />
        </div>
        <div className="column is-two-thirds">
          <h3 className="title is-size-3-fullhd is-size-4">
            {data.cards[2].title}
          </h3>
          <p className="is-size-5-fullhd is-size-6">
            {data.cards[2].description}
          </p>
        </div>
      </div>

      <div className={`columns ${styles.reversed}`}>
        <div className="column is-two-thirds">
          <h3 className="title is-size-3-fullhd is-size-4">
            {data.cards[3].title}
          </h3>
          <p className="is-size-5-fullhd is-size-6">
            {data.cards[3].description}
          </p>
        </div>
        <div className="column is-one-third">
          <Img
            className={`${styles.image} ${styles.ml_auto}`}
            fluid={images[0]}
          />
        </div>
      </div>

      <div className="columns is-centered">
        <div className="column">
          <button
            className={`button is-large is-primary ${styles.button}`}
            onClick={() => scrollTo('#contact')}
          >
            {data.button}
          </button>
        </div>
      </div>
    </div>
  </section>
)

export default Services
