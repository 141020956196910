import React from 'react'
import Img from 'gatsby-image'
import styles from '../styles/footer.module.scss'

const Footer = ({ data, image }) => (
  <footer className={`has-background-light has-text-centered ${styles.footer}`}>
    <div className="container">
      <div className="mb2">
        <Img fixed={image} alt="NessIA Logo" />
      </div>
      {`${data.company_name} \u00A9 ${new Date().getFullYear()}`}
    </div>
  </footer>
)

export default Footer
