import React from 'react'
import Img from 'gatsby-image'
import Carousel from 'nuka-carousel'
import { TestimonialCard, LogoCard } from './TestimonialCard'
import Title from './Title'
import AngleLeft from '../images/angle-left-solid.svg'
import AngleRight from '../images/angle-right-solid.svg'
import styles from '../styles/testimonials.module.scss'

/**
 * Displays the testimonial data and logos.
 * @param {Object} props
 * @param {Object} props.data testimonial data from json files
 * @param {Object[]} props.data.cards array of individual recommendations
 * @param {string} [props.data.cards.quote] optional quote
 * @param {string} [props.data.cards.name] optional name
 * @param {string} props.data.cards.link
 * @param {string} props.data.cards.image_id
 * @param {Object[]} props.images logos from `images/logos/`
 */
const Testimonials = ({ data, images }) => {
  // Prepare a `cards` object with each `image_id` as keys
  const cards = data.cards.reduce((obj, card) => {
    obj[card.image_id] = card
    return obj
  }, {})

  // Generate the arrays of Testimonial components to be included in the carousel or outside
  const carouselCards = []
  const logos = []

  images.forEach(({ node: { name, childImageSharp } }) => {
    const logo = () => (
      <Img alt={`${name} logo`} fluid={childImageSharp.fluid} />
    )
    logos.push(<LogoCard key={name} Logo={logo} link={cards[name].link} />)

    // 2024-08-12:
    // Commenting code to disable testimonials (not enough value),
    // they will appear as logos only
    // If a quote exists, it is a testimonial, else it is a logo
    // if (cards[name].quote) {
    //   carouselCards.push(
    //     <TestimonialCard key={name} Logo={logo} data={cards[name]} />
    //   )
    // } else {
    //   logos.push(<LogoCard key={name} Logo={logo} link={cards[name].link} />)
    // }
  })

  return (
    <section
      id="testimonials"
      className="section is-medium has-background-white"
    >
      <div className="container">
        <Title title={data.title} subtitle={data.subtitle} />
        {/* <Carousel
          autoplay
          autoplayInterval={5000}
          wrapAround
          easing="easeSinOut"
          framePadding="0 5vw"
          renderCenterLeftControls={({ previousSlide }) => (
            <AngleLeft className={styles.chevron} onClick={previousSlide} />
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <AngleRight className={styles.chevron} onClick={nextSlide} />
          )}
          renderBottomCenterControls={({
            goToSlide,
            slideCount,
            currentSlide,
          }) => (
            <div className={styles.slider_control_bottomcenter}>
              <ul>
                {Array.apply(null, Array(slideCount)).map((val, i) => (
                  <li key={i}>
                    <button
                      onClick={() => goToSlide(i)}
                      aria-label={`slide ${i + 1} bullet`}
                      className={
                        currentSlide === i ? 'slide_selected' : undefined
                      }
                    />
                  </li>
                ))}
              </ul>
            </div>
          )}
        >
          {carouselCards}
        </Carousel> */}

        <div className={`${styles.image_container} container`}>{logos}</div>
      </div>
    </section>
  )
}

export default Testimonials
