import React from 'react'
import Img from 'gatsby-image'
import scrollTo from 'gatsby-plugin-smoothscroll'
import styles from '../styles/hero.module.scss'

const Hero = ({ data, image }) => (
  <section
    className={`section hero is-fullheight-with-navbar has-background-white ${styles.background}`}
  >
    <div className={styles.hero_image}>
      <Img fluid={image} />
    </div>
    <div className="container">
      <div className="columns">
        <div
          className={`column is-half has-text-centered-mobile ${styles.text}`}
        >
          <h1 className="title is-spaced is-size-1-fullhd is-size-2">
            {data.title}
          </h1>
          <h2 className="subtitle is-size-3-fullhd is-size-4">
            {data.subtitle}
          </h2>
          <button
            className="button is-primary is-large"
            onClick={() => scrollTo('#services')}
          >
            {data.button}
          </button>
        </div>
      </div>
    </div>
  </section>
)

export default Hero
