import React from 'react'
import Title from './Title'

const Contact = ({ data, locale }) => (
  <section id="contact" className="section is-medium has-background-light">
    <Title title={data.title} subtitle={data.subtitle} __className="mb3" />

    <div className="columns is-centered is-mobile">
      <form
        className="column is-two-thirds-tablet is-half-desktop"
        data-netlify="true"
        method="post"
        name="contact"
        action={locale === 'fr' ? '/form/' : '/form?lang=en'}
      >
        <input type="hidden" name="form-name" value="contact" />
        <div className="field">
          <label className="label" htmlFor="Nom">
            {data.form.name}
          </label>
          <div className="control">
            <input className="input" type="text" name="Nom" id="Nom" required />
          </div>
        </div>
        <div className="field">
          <label className="label" htmlFor="Email">
            {data.form.email}
          </label>
          <div className="control">
            <input
              className="input"
              type="email"
              name="Email"
              id="Email"
              required
            />
          </div>
        </div>
        <div className="field">
          <label className="label" htmlFor="Message">
            {data.form.message}
          </label>
          <div className="control">
            <textarea
              className="textarea"
              name="Message"
              id="Message"
              required
            />
          </div>
        </div>
        <div className="field is-grouped is-grouped-centered">
          <div className="control">
            <button className="button is-primary is-medium" type="submit">
              {data.form.button}
            </button>
          </div>
        </div>
      </form>
    </div>
  </section>
)

export default Contact
